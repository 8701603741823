import React from "react"

import AnimatedStarsBackground from "../decoration/stars"

const BlogStarsBackground = () => (
  <AnimatedStarsBackground
    s={[
      {
        x: `80%`,
        y: `15%`,
        color: `gatsby`,
        scale: 0.5,
        opacity: 1,
      },
      {
        x: `12%`,
        y: `58%`,
        color: `gatsby`,
        scale: 0.4,
        opacity: 1,
      },
      {
        x: `5%`,
        y: `35%`,
        color: `gatsby`,
        scale: 0.5,
        opacity: 1,
      },
      {
        x: `10%`,
        y: `70%`,
        color: `gatsby`,
        scale: 0.25,
        opacity: 1,
      },
      {
        x: `15%`,
        y: `52%`,
        color: `bright`,
        scale: 0.35,
        opacity: 0.8,
      },
      {
        x: `25%`,
        y: `35%`,
        speed: -2,
        color: `accent`,
        scale: 0.25,
        opacity: 1,
      },
      {
        x: `47%`,
        y: `42%`,
        color: `bright`,
        scale: 0.5,
        opacity: 1,
      },
      {
        x: `58%`,
        y: `52%`,
        color: `bright`,
        scale: 0.3,
        opacity: 1,
      },
      {
        x: `10%`,
        y: `50%`,
        color: `bright`,
        scale: 0.4,
        opacity: 1,
      },
      {
        x: `20%`,
        y: `30%`,
        color: `accent`,
        scale: 0.25,
        opacity: 1,
      },
      {
        x: `40%`,
        y: `30%`,
        speed: -2,
        color: `bright`,
        scale: 0.5,
        opacity: 1,
      },
      {
        x: `85%`,
        y: `20%`,
        color: `gatsby`,
        scale: 0.25,
        opacity: 1,
      },
      {
        x: `70%`,
        y: `20%`,
        speed: -2,
        color: `bright`,
        scale: 0.35,
        opacity: 1,
      },
      {
        x: `90%`,
        y: `10%`,
        color: `accent`,
        scale: 0.35,
        opacity: 1,
      },
      {
        x: `75%`,
        y: `10%`,
        color: `bright`,
        scale: 0.25,
        opacity: 1,
      },
      {
        x: `75%`,
        y: `25%`,
        color: `bright`,
        scale: 0.5,
        opacity: 1,
      },
      {
        x: `51%`,
        y: `82%`,
        color: `bright`,
        scale: 0.5,
        opacity: 1,
      },
      {
        x: `21%`,
        y: `36%`,
        color: `accent`,
        scale: 0.3,
        opacity: 1,
      },
      {
        x: `28%`,
        y: `5%`,
        color: `bright`,
        scale: 0.2,
        opacity: 1,
      },
      {
        x: `32%`,
        y: `8%`,
        speed: -2,
        color: `gatsby`,
        scale: 0.5,
        opacity: 1,
      },
      {
        x: `65%`,
        y: `60%`,
        color: `accent`,
        scale: 0.25,
        opacity: 1,
      },
      {
        x: `70%`,
        y: `49%`,
        speed: -2,
        color: `bright`,
        scale: 0.35,
        opacity: 1,
      },
      {
        x: `87%`,
        y: `13%`,
        color: `gatsby`,
        scale: 0.3,
        opacity: 1,
      },
      {
        x: `25%`,
        y: `78%`,
        color: `bright`,
        scale: 0.25,
        opacity: 1,
      },
      {
        x: `55%`,
        y: `47%`,
        color: `bright`,
        scale: 0.5,
        opacity: 1,
      },

      {
        x: `41%`,
        y: `72%`,
        color: `accent`,
        scale: 0.35,
        opacity: 1,
      },
    ]}
    containerCSS={{
      height: `635px`,
    }}
  />
)

export default BlogStarsBackground
